import React, { useLayoutEffect, useState } from 'react';
import logo from './logo_website.jpeg' 
import './App.css';
import {Route, BrowserRouter, Routes} from 'react-router-dom'
import Products from './views/ProductsComponent';
import NavigationBar from './modules/NavBar';
import Home from './views/Home';
import AboutUs from './views/AboutUsComponent';
import Footer from './modules/Footer';
import NotFoundComponent from './views/NotFoundComponent';
const  App=()=> {

  return (
    <div >
      <div className='header'>
      <NavigationBar></NavigationBar>
      </div>
    <BrowserRouter>
    <Routes>
          <Route index element={<Home />} />
          {/* <Route path="/home" element={<Home />} /> */}
          <Route path="/products" element={<Products/>} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path='*' element={<NotFoundComponent />}/>
    </Routes>
    </BrowserRouter>
    <div className='footer'>
    <Footer></Footer>
    </div>
    </div>
  );
}

export default App;
