import React from "react";

const NotFoundComponent = () => {
  return (
    <div className="container-fluid">
    <div className="d-flex flex-wrap justify-content-center">
      <h1 className="col-12">Error: 404</h1>
      <h3 className="col-12">This page is not available!</h3>
    </div>
    </div>
  );
};

export default NotFoundComponent;
