import React from "react";
import { Business, Phone, Email, Facebook, Google } from "@mui/icons-material";
const Footer = () => {
  return (
    <div className="my-5">
      <footer className="text-center text-lg-start text-white footerColor">
        <div className="container p-4 pb-0">
          <section className="">
            <div className="row">
              <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
                <h6 className="text-uppercase mb-4 font-weight-bold">
                  Life Organics
                </h6>
                <p>
                  Established in 2020, Life Organics is committed to becoming
                  the foremost leader in the field of Healthcare with the blend
                  of Allopathy, Ayurvedic and Nutraceutical products. We aim to
                  provide a comprehensive range of natural solutions for
                  holistic well-being, blending the ancient wisdom of Ayurveda
                  with the latest scientific advancements.
                </p>
              </div>

              <hr className="w-100 clearfix d-md-none" />

              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
                <h6 className="text-uppercase mb-4 font-weight-bold">
                  Useful links
                </h6>
                <p>
                  <a className="textColor" href="/">
                    Home
                  </a>
                </p>
                <p>
                  <a className="textColor" href="/products">
                    Products
                  </a>
                </p>
                <p>
                  <a className="textColor " href="/aboutUs">
                    About Us
                  </a>
                </p>
              </div>

              <hr className="w-100 clearfix d-md-none" />
              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
                <h6 className="text-uppercase mb-4 font-weight-bold">
                  Contact Us
                </h6>
                <p>
                  <Business /> H.no: 4-7-8/11/6,
                </p>
                <p>Street No: 3, Annapurna Colony</p>
                <p>Nacharam, Hyderabad - 500076</p>
                <p>
                  <Email /> customercare@lifeorganics.in
                </p>
                <p>
                  <Phone /> + 91 8500631058
                </p>
              </div>
            </div>
          </section>

          <hr className="my-3" />
          <section className="p-3 pt-0">
            <div className="row d-flex align-items-center">
              <div className="col-md-7 col-lg-8 text-center text-md-start">
                <div className="p-3">
                  © 2020 Copyright:{" "}
                  <a className="textColor" href="/">
                    lifeorganics.in
                  </a>
                </div>
              </div>
              <div className="col-md-5 col-lg-4 ml-lg-0 text-center text-md-end">
                <a
                  className="btn btn-outline-light btn-floating m-1 textColor"
                  role="button"
                  href="https://www.facebook.com/LifeOrganicsPharma/"
                >
                  <Facebook />
                </a>
                <a
                  className="btn btn-outline-light btn-floating m-1 textColor"
                  role="button"
                  href="https://www.google.com/search?gs_ssp=eJzj4tVP1zc0TCovTCoyjjczYLRSNagwTkpOsrQ0NTc0NDO3NEi2tDKoMEkyT7U0Skk1TkwxTky0TPXizclMS1XIL0pPzMtMLgYAqhUUqQ&q=life+organics&oq=life+organics&aqs=chrome.1.0i355i512j46i175i199i512j0i512l3j69i60l3.5391j0j7&sourceid=chrome&ie=UTF-8"
                >
                  <Google />
                </a>
              </div>
            </div>
          </section>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
