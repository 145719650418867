import React, { useLayoutEffect } from "react";
import logo from "./logo_website.jpeg";
import "../App.css";
const AboutUs = () => {
  return (
    <div className="container-fluid ">
      <div className="d-flex flex-row flex-wrap m-3 p-3 gap-3">
        <div className="col-12">
          <h1 className="heading  pe-4">About Us</h1>
          <p className="details pe-2">
            Established in 2020, Life Organics is committed to becoming the
            foremost leader in the field of Healthcare with the blend of Allopathy,
            Ayurvedic and Nutraceutical products. We aim to provide a
            comprehensive range of natural solutions for holistic well-being,
            blending the ancient wisdom of Ayurveda with the latest scientific
            advancements.
          </p>
          <p className="details pe-2">
            Join us at Life Organics and embark on a journey towards holistic
            well-being. Together, let's unlock the potential of nature and
            empower ourselves to live healthier, happier lives.
          </p>
        </div>
        <div className="col-12">
          <h1 className="heading  pe-4">Our Vision</h1>
          <p className="details">
            Our vision is to establish Life Organics as the foremost leader 
            in the field of Healthcare with the blend of Allopathy, Ayurvedic and Nutraceutical products, offering a
            comprehensive range of natural solutions for holistic well-being. By
            harnessing the ancient wisdom of Ayurveda and incorporating the
            latest scientific advancements, we strive to deliver products that
            promote optimal health, enhance vitality, and nurture the mind,
            body, and spirit. Through our commitment to purity, sustainability,
            and innovation, we aspire to inspire individuals to embrace a
            natural and balanced lifestyle, empowering them to live their lives
            to the fullest.
          </p>
        </div>
        <div className="col-12">
          <h1 className="heading  pe-4">Our Mission</h1>
          <p className="details">
            Our mission at Life Organics is to integrate the wisdom of Ayurveda
            and traditional medicine with modern scientific research to provide
            effective and safe Ayurvedic and nutraceutical products. We are
            dedicated to harnessing the power of nature to promote wellness,
            balance, and vitality in individuals' lives. By offering
            high-quality, authentic, and ethically sourced products, we strive
            to be a trusted companion on our customers' journey toward optimal
            health. Our mission extends beyond commerce as we actively
            contribute to the preservation of traditional knowledge, support
            sustainable practices, and empower individuals to take charge of
            their well-being naturally.
          </p>
        </div>
        <div className="col-12">
          <h1 className="heading  pe-4">Our Goals</h1>
          <p className="details pe-2">
            1. Provide High-Quality Products: Life Organics aims to develop and
            offer high-quality Ayurvedic and nutraceutical products that meet
            the highest standards of purity, authenticity, and effectiveness.
            They strive to source premium ingredients and ensure rigorous
            quality control throughout the production process.
          </p>
          <p className="details pe-2">
            2. Promote Holistic Well-being: The company's primary goal is to
            support individuals in achieving holistic well-being by providing
            products that address physical, mental, and spiritual aspects of
            health. They aim to offer comprehensive solutions that nurture and
            balance the mind, body, and spirit.
          </p>
          <p className="details pe-2">
            3. Embrace Tradition and Innovation: Life Organics seeks to honor the
            ancient wisdom of Ayurveda and traditional medicine while embracing
            modern scientific advancements. They aim to integrate traditional
            knowledge with evidence-based research to create innovative and
            effective products that cater to the evolving needs of their
            customers.
          </p>
          <p className="details pe-2">
            4. Educate and Empower: The company aspires to educate and empower
            individuals about the benefits of Ayurveda and natural approaches to
            health. They aim to provide resources, information, and guidance to
            help customers make informed choices and take an active role in
            their well-being.
          </p>
          <p className="details pe-2">
            5. Foster Sustainability: Life Organics is committed to promoting
            sustainability and environmental stewardship. They aim to source
            ingredients responsibly, minimize their ecological footprint, and
            support practices that contribute to the long-term health of the
            planet.
          </p>
          <p className="details pe-2">
            6. Become a Leading Brand: Ultimately, Life Organics strives to
            become a leading brand in the Ayurvedic and nutraceutical industry.
            They aim to build a strong reputation for quality, reliability, and
            customer satisfaction, earning the trust and loyalty of their
            customers worldwide.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
